<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <card>
        <h1>Politique de confidentialité des données</h1>
        <div class="privacy-policy-content">
          <p>La présente Politique de confidentialité des données vise à expliquer comment les données personnelles sont collectées et traitées par la Conférence intercantonale de l'instruction publique (désignée ci-après « CIIP »). Elle s'applique à toute information obtenue lors de votre utilisation d'un site web CIIP.</p>

          <h2>Principe du traitement et de la protection des données</h2>
          <p>
            Les données collectées sur les services numériques fournies par la CIIP sont traitées conformément aux dispositions légales en vigueur en Suisse; la base légale est la loi fédérale sur la protection des données LPD et l'ordonnance correspondante OLPD.
          </p>
          <p> 
            Les données personnelles sont traitées de manière strictement confidentielle et uniquement dans le but de fournir les prestations de la CIIP. Elles ne sont ni vendues, ni transmises à des tiers.
          </p>

          <h2>Quelles informations sont collectées? Comment sont-elles collectées? Comment sont-elles conservées ? Dans quel but ?</h2>
          <p>
            Lorsque vous consultez une de nos plateformes web, nos serveurs enregistrent automatiquement des informations sur votre visite. Par exemple :
          </p>
          <ul>
            <li>
              L'adresse IP, le type d'appareil, le système d'exploitation, le nom et la version du navigateur utilisé
            </li>
            <li>
              La date et l'heure de votre requête
            </li>
            <li>
              Les pages web CIIP que vous consultez
            </li>
            <li>
              La date et la durée de votre visite
            </li>
          </ul>

          <p>
            L'enregistrement de ces informations nous permet d'analyser les erreurs, de garantir le bon fonctionnement et la sécurité de nos systèmes informatiques. Ces données sont supprimées à la fin de chaque session.
          </p>
          <p>
            Par ailleurs, nous collectons les données personnelles que vous fournissez lorsque vous remplissez un formulaire en ligne et communiquez vos coordonnées (nom, prénom, sexe, adresse, adresse e-mail, n° de téléphone/fax) ou lorsque vous vous inscrivez à une de nos newsletters électroniques.
          </p>
          <p>
            Les données collectées sont utiles à l'amélioration de la qualité de nos prestations et à la gestion de demandes. Elles permettent de mémoriser votre login, mais aussi à établir une évaluation statistique anonyme de l'utilisation de nos sites.
          </p>
          <p>
            Lorsque vous vous connectez à une plateforme CIIP, des données personnelles sont enregistrées.
          </p>
          <p>
            Ces données proviennent pour la plupart de fournisseurs d'identités de directions d'instructions publiques cantonales ou d'hautes écoles pédagogiques, mais également de fournisseur d'identité comme google ou facebook. La liste des catégories de données récupérées est la suivante :
          </p>
          <ul class="data-categories">
            <li>
              Prénom
            </li>
            <li>
              Nom
            </li>
            <li>
              Catégorie d'âge
            </li>
            <li>
              Langue
            </li>
            <li>
              Rôle
            </li>
            <li>
              Adresse courriel
            </li>
            <li>
              Établissement
            </li>
            <li>
              Niveau d'enseignement
            </li>
            <li>
              Cycle
            </li>
            <li>
              Canton
            </li>
            <li>
              Fonction
            </li>
            <li>
              Identificateur Technique
            </li>
            <li>
              Année de naissance
            </li>
          </ul>

          <p>
            Ces données sont stockées sur les serveurs de la CIIP.
          </p>
          <p>
            Afin d'offrir une meilleure expérience les sites CIIP utilisent des technologies de traçabilité comme les cookies (fichiers textes qui sont installés par l'intermédiaire de votre navigateur sur le système d'exploitation de votre appareil lorsque vous consultez nos pages).
          </p>
          <p>
            Ils nous permettent de reconnaître votre navigateur lorsque vous consultez à nouveau nos pages et de mémoriser certains de vos réglages pour vos prochaines visites.
          </p>
          <p>
            Les cookies sont stockés sur votre ordinateur: c'est vous qui décidez de l'utilisation qui en est faite. Vous pouvez, si vous le souhaitez, tous les effacer ou alors modifier les paramètres de votre navigateur Internet de façon à limiter ou bloquer leur installation sur votre ordinateur.
          </p>
          <p>
            Le fait de refuser, de bloquer ou de désactiver des technologies de traçabilité peut se traduire par une disponibilité réduite des services fournis ou empêcher le bon fonctionnement de certaines parties des sites web CIIP.
          </p>

          <h2>Comment pouvez-vous accéder à vos données personnelles ou les modifier ?</h2>
          <p>
            Vous avez le droit d'obtenir la confirmation que des données personnelles vous concernant sont traitées ou non par la CIIP. Si c'est le cas, vous pouvez demander des informations précises portant sur le traitement effectué.
          </p>
          <p>
            Vous avez le droit de demander la suppression des données personnelles vous concernant.
          </p>
          <p>
            Vous avez, par ailleurs, le droit de faire rectifier les données personnelles vous concernant lorsque celles-ci sont erronées.
          </p>
          <p>
            Si vous avez des questions concernant le traitement de vos données personnelles, veuillez nous écrire à l'adresse suivante :
          </p>

          <p>
            FAUBOURG DE L'HÔPITAL 68<br/>
            CASE POSTALE 556<br/>
            CH - 2002 NEUCHÂTEL<br/>
            TÉL. +41 32 889 69 72<br/>
            FAX +41 32 889 69 73<br/>
          </p>
          <p>
            <a href="mailto:CIIP@NE.CH">CIIP@NE.CH</a>
          </p>

          <p>
            La CIIP a mis en œuvre des mesures de sécurité appropriées de nature technique et organisationnelle afin d'assurer une protection adéquate de vos données personnelles collectées contre tout accès non autorisé, tout abus, toute perte ou destruction desdites données. En accédant aux site web de la CIIP, vous acceptez la présente politique de confidentialité des données. Cette politique est susceptible d'être mise à jour à tout moment. Nous vous invitons donc à la consulter régulièrement.
          </p>

          <p class="last-update">
            Dernière mise à jour : Neuchâtel, Septembre 2020.
          </p>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  Collapse,
  CollapseItem,
  Tabs,
  TabPane,
  Card,
} from "@/components/Template";

export default {
  components: {
    TabPane,
    Tabs,
    CollapseItem,
    Collapse,
    Card,
  },
  methods: {
  },
};
</script>
<style scoped>
h3,
h4 {
  color: #83000e;
}
.continue {
  float: right;
}

.data-categories {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.last-update {
  text-align: right;
}

</style>
