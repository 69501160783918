<template>
  <div>
    <resource-modal
      :show="show"
      :resourceProp="resourceSelected"
      @close="closeModal"
      :isUpdate="isModalUpdate"
    />

    <el-row :gutter="20">
      <el-col :span="23">
        <h1>Applications</h1>
      </el-col>
      <el-col :span="1">
        <el-button @click="handleAddClick" type="primary" icon="el-icon-plus" circle></el-button>
      </el-col>
      <el-col 
        v-for="resource in resources"
        :key="resource.id"
        :xs="24"
        :sm="12"
        :md="8"
        class="resource-col"
      >
        <el-card class="card-resource">
          <el-row type="flex" justify="space-between">
            <el-col :span="23"><h6>{{ resource.name }}</h6></el-col>
            <el-col :span="1"><i @click="handleEditClick(resource)" class="el-icon-edit"></i></el-col>
          </el-row>
          <span>Roles</span>
          <ul v-if="resource.rolesString.length">
            <li v-for="role in resource.rolesString" :key="role">{{ role }}</li>
          </ul>
          <p v-else class="no-roles-text">Aucun roles n'est définit pour cette application</p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import ROLES from '@/enums/Roles';
import { Card, Row, Col } from 'element-ui';
import ResourceModal from '@/components/Modals/ResourceModal.vue'
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Card.name]: Card,
    ResourceModal,
  },
  data() {
    return {
      resources: [],
      show: false,
      resourceSelected: {},
      isModalUpdate: false,
    }
  },
  mounted() {
    this.fetchAccounts();
  },
  computed: {
    isNoRoles(resource) {
      return resource.roles.isEmpty();
    }
  },
  methods: {
    async fetchAccounts(pageNumber, pageSize) {
      try {
        const response = await AxiosApiService.get('/resources');

        if (response && response.data) {
          let resources = response.data;
          resources = resources.map((resource) => {
            return {
              ...resource,
              rolesString: this.formatRoles(resource.roles),
            }
          })
          this.resources = resources;
        }
        return response;
      } catch (error) {
        this.$notify({
          message: 'Echec de la récupération des comptes. Veuillez contacter un administrateur.',
          icon: 'add_alert',
          type: 'danger',
          horizontalAlign: 'right',
          verticalAlign: 'bottom',
        });
      }
    },
    formatRoles(roles) {
      let rolesString = [];
      roles.forEach((role) => rolesString.push(Object.values(ROLES).filter((appRole => appRole.name === role))[0].string));
      return rolesString;
    },
    handleEditClick(resource) {
      this.resourceSelected = resource;
      this.isModalUpdate = true;
      this.show = true;
    },
    handleAddClick() {
      this.isModalUpdate = false;
      this.show = true;
    },
    closeModal(){
      this.resourceSelected = {
        name: '',
        roles: [],
      };
      this.show = false;
      this.fetchAccounts();
    }
  }
}
</script>

<style lang="scss" scoped>
 .resource-col {
   margin-bottom: 20px;
 }
 .card-resource {
   height: 230px;
 }
 .no-roles-text {
   font-size: 12px;
 }
</style>
