<template>
  <div>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :md="24" :lg="20" :xl="16">
        <el-card class="box-card">
          <!-- ACCOUNT INFO -->
          <el-row type="flex" align="middle" class="info-profile-container">
            <el-col :sm="24" :md="6" class="info-profile">
              <el-row>
                <el-col><label>Prénom</label></el-col>
              </el-row>
              <el-row>
                <el-col><span>{{ account.firstName }}</span></el-col>
              </el-row>
            </el-col>
            <el-col :sm="24" :md="6" class="info-profile">
              <el-row>
                <el-col><label>Nom</label></el-col>
              </el-row>
              <el-row>
                <el-col><span>{{ account.lastName }}</span></el-col>
              </el-row>
            </el-col>
            <el-col :sm="24" :md="6" class="info-profile">
              <el-row>
                <el-col><label>Email</label></el-col>
              </el-row>
              <el-row>
                <el-col><span>{{ account.email }}</span></el-col>
              </el-row>
            </el-col>
            <el-col :sm="24" :md="6" class="info-profile">
              <el-row>
                <el-col><label>Organisation</label></el-col>
              </el-row>
              <el-row>
                <el-col><span>{{ account.organization }}</span></el-col>
              </el-row>
            </el-col>
            <!--<el-col :span="3">
              <el-row type="flex" class="row-bg" justify="end">
                <el-col :span="10"><i @click="showChangeModal" class="el-icon-edit"></i></el-col>
              </el-row>
            </el-col>-->
          </el-row>
        </el-card>
        <el-row :gutter="10" class="resources-container">
          <el-col
            :sm="24"
            :md="24"
            :lg="12"
            :xl="8"
            v-for="resource in account.resources"
            :key="resource.key"
            class="resource"
          >
            <el-card>
              <h5
                @click="handleResourceClick(resource.url)"
                class="resource-title"
              >
                {{resource.name}}
              </h5>
              <span>Role(s): {{ resource.rolesString }}</span>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { Col, Row, Card, Collapse, Table } from 'element-ui';
import ROLES from '@/enums/Roles';
import ChangeMeModal from '@/components/Modals/ChangeMeModal.vue';
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Card.name]: Card,
    [Collapse.name]: Collapse,
    [Table.name]: Table,
    ChangeMeModal,
  },
  mounted() {
    this.fetchAccount();
  },
  data() {
    return {
      account: {
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
      },
      show: false,
    }
  },
  methods: {
    async fetchAccount() {
      try {
        const response = await AxiosApiService.get('/accounts/me');

        if (response && response.data) {
          const accountRaw = response.data;
          accountRaw.resources = accountRaw.resources.map((resource) => {
            return {
              ...resource,
              rolesString: this.formatRoles(resource.roles),
            }
          });
          this.account = accountRaw;
        }
      } catch (error) {
        this.$notify({
          message: 'Une erreur est survenu lors de la récupération de vos informations, veuillez contacter un administrateur',
          type: 'error',
        })
      }
    },
    formatRoles(roles){
      let rolesString = [];
      roles.forEach((role) => {
        const rolesFormatted = Object.values(ROLES).filter((appRole => appRole.name === role));
        if (rolesFormatted.length > 0){
          rolesString.push(rolesFormatted[0].string);
        }
      });
      return rolesString.join(', ');
    },
    handleResourceClick(link){
      window.location = link;
    },
    showChangeModal() {
      this.show = true;
    },
    closeChangeModal() {
      this.show = false;
    },
    async updatePassword(account) {
      try {
        const response = await AxiosApiService.post('/accounts/me/changePassword', account);
        this.closeChangeModal();
        return response;
      } catch (error) {
        this.$notify({
          message: 'Une erreur est survenu lors de la modification de votre mot de passe, veuillez contacter un administrateur',
          type: 'error',
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: bold;
}
.resources-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.resource {
  margin-top: 10px;
}
.resource-title:hover {
  cursor: pointer;
  text-decoration: underline;
}
.info-profile-container {
  flex-wrap: wrap;
}
.info-profile {
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
