export default {
  namespaced: true,
  state: {
    dyslexicMode: false,
    colorblindMode: false
  },
  mutations: {
    setDyslexicMode(state, dyslexicMode) {
      state.dyslexicMode = dyslexicMode;
      localStorage.setItem("dyslexicMode", JSON.stringify(dyslexicMode));
    },
    setColorblindMode(state, colorblindMode) {
      state.colorblindMode = colorblindMode;
      localStorage.setItem("colorblindMode", JSON.stringify(colorblindMode));
    }
  },
  actions: {
    init({ commit, dispatch }) {
      console.log("Initializing settings store");

      commit(
        "setDyslexicMode",
        localStorage.getItem("dyslexicMode") === "true"
      );
      commit(
        "setColorblindMode",
        localStorage.getItem("colorblindMode") === "true"
      );
    }
  },
  getters: {
    dyslexicMode: state => state.dyslexicMode,
    colorblindMode: state => state.colorblindMode
  }
};
