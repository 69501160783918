import AxiosAuthService from "../axios/axios.auth.service.js";
import AxiosApiService from "../axios/axios.api.service.js";

const AuthorizationService = {
  // Autorization can onyl be obtain with authentication using shibboleth. 
  // This can only be attained by using a running environnement (API, SP and IDP), 
  // so we use a different BASE_URL to call the correct API
  async getJWT() {
    return AxiosAuthService.get('/auth/jwt');
  },
  // Here we can use the axios api service as we can get an autorization in localhost using oauth2
  async oauth2() {
    return AxiosApiService.get('/oauth2/jwt');
  },
  async logoutAPI() {
    return AxiosApiService.get('/logout');
  },
};

export default AuthorizationService;