// Pages
import Profile from '@/views/Profile.vue';
import AccountDetails from '@/views/AccountDetails.vue';
import Accounts from '@/views/Accounts.vue';
import Resources from '@/views/Resources.vue';
import Idp from '@/views/Idp.vue';

// Authentication
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ResetPasswordRequest from '@/views/ResetPasswordRequest.vue';

// GeneralViews
import NotFound from '@/views/NotFoundPage.vue';
import NotAllowedPage from '@/views/NotAllowedPage.vue';

// Layout
import DashboardLayout from '@/layout/DashboardLayout.vue';

// Roles
import ROLES from '@/enums/Roles';

// Privacy Policy
import PrivacyPolicy from '@/views/PrivacyPolicyPage.vue';

// Password reset pages
const requestResetPasswordPage = {
  path: '/reset/resetPasswordRequest',
  name: 'ResetPasswordRequest',
  component: ResetPasswordRequest,
  permissions: {
    lvl: 0,
  },
};

const resetPasswordPage = {
  path: '/reset/resetPassword',
  name: 'ResetPassword',
  component: ResetPassword,
  permissions: {
    lvl: 0,
  },
  props: route => ({
    secretKey: route.query.secretKey,
    email: route.query.email,
  }),
};

const notAllowed = {
  path: '/notAllowed',
  name: 'NotAllowed',
  component: NotAllowedPage,
  permissions: {
    lvl: 0,
  }
};

const login = {
  path: '/login',
  name: 'Login',
  component: Login,
  permissions: {
    lvl: 0,
  }
}

const routes = [
  requestResetPasswordPage,
  resetPasswordPage,
  notAllowed,
  login,
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: '',
        redirect: '/profile',
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        permissions: {
          auth: true,
        },
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: Accounts,
        permissions: {
          roles: [ROLES.ADMINISTRATOR, ROLES.POWER_USER],
        },
      },
      {
        path: 'accounts/creation',
        name: 'accountCreation',
        component: AccountDetails,
        permissions: {
          roles: [ROLES.ADMINISTRATOR],
        },
      },
      {
        path: 'accounts/:id',
        name: 'accountDetails',
        component: AccountDetails,
        permissions: {
          roles: [ROLES.ADMINISTRATOR, ROLES.POWER_USER],
        },
      },
      {
        path: 'resources',
        name: 'resources',
        component: Resources,
        permissions: {
          roles: [ROLES.ADMINISTRATOR, ROLES.POWER_USER],
        }
      },
      {
        path: 'idps',
        name: 'idps',
        component: Idp,
        permissions: {
          roles: [ROLES.ADMINISTRATOR, ROLES.POWER_USER],
        }
      },
      {
        path: '/privacy_policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        permissions: {
          lvl: 0,
        },
      },
    ],
  },
  { path: '*', component: NotFound },
];

export default routes;
