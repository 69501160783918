import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { isAllowed } from "../services/AuthService";
import { verifyAndRenewToken } from "@/services/AuthService";
import routes from '@/routes/routes';

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters["account/token"]) {
      try {
        await store.dispatch("account/login", false);
        //await store.dispatch("account/loginOAuth2", false);
      } catch (error) {
        console.log("Session is not available", error);
      }
  } else {
    await verifyAndRenewToken();
  }

  if (isAllowed(to)) {
    return next();
  } else {
    Vue.prototype.$notifications.notify({
      message: "Vous n'avez pas les droits pour accéder à cette partie de l'application. Veuillez demander à un administrateur",
      title: "Autorisation manquante",
      icon: "fas fa-times",
      horizontalAlign: "right",
      verticalAlign: "bottom",
      type: "danger",
      timeout: 5000,
    });
    return next('/login');
  }
});

export default router;