import Axios from 'axios';
import { verifyAndRenewToken } from "@/services/AuthService";
import store from '@/store';

const AxiosApiService = {
  init(baseURL) {
    this.axios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: baseURL + "/api",
      withCredentials: true,
    });

    this.axios.interceptors.request.use(async function(config) {
      if (store.getters["account/token"]) {
        await verifyAndRenewToken();
        config.headers.Authorization = `Bearer ${store.getters["account/token"]}`;
      }
    
      return config;
    });

    this.axios.interceptors.response.use(
      (config) => config,
      async (error) => {
        // Logs out whenever there is an unauthorized response.
        if (error.response && error.response.status === 401) {
          await store.dispatch("account/logout");
        }

        return Promise.reject(error);
      },
    );
  },

  /**
   * Sends a GET request with a json body to the API.
   * @param {String} path A relative path to the resource.
   * @param {*} query Object used to create a query string (if not null, path is considered not to contain a query string).
   */
  async get(path, query = null) {
    let queryString = '';
    if (query && query instanceof Object) {
      Object.entries(query).forEach(([key, value], index) => {
        queryString += index > 0 ? '&' : '?';
        queryString += `${key}=${value}`;
      });
    }

    return this.axios.get(path + queryString);
  },

  /**
   * Sends a POST request with a json body to the API.
   * @param {String} path A relative path to the ressource.
   * @param {*} body The request's body.
   * @param {*} query Object used to create a query string (if not null, path is considered not to contain a query string).
   */
  async post(path, body = {}, query = null) {
    let queryString = '';
    if (query && query instanceof Object) {
      Object.entries(query).forEach(([key, value], index) => {
        queryString += index > 0 ? '&' : '?';
        queryString += `${key}=${value}`;
      });
    }
    return this.axios.post(path + queryString, body);
  },

  /**
   * Sends a PATCH request with a json body to the API.
   * @param {String} path A relative path to the ressource.
   * @param {*} body The request's body.
   */
  async patch(path, body = {}) {
    return this.axios.patch(path, body);
  },

  /**
   * Sends a DELETE request with a json body to the API.
   * @param {String} path A relative path to the ressource.
   */
  async delete(path) {
    return this.axios.delete(path);
  },
};

export default AxiosApiService;