<template>
	<div>
		<crud-list
			:fetch-data="fetch"
			ref="crudList"
			title="Forunisseurs d'identités"
			:createAllowedAccount="createAllowedAccount"
			:defaultSort="{ prop: 'displayName', order: 'ascending' }"
			@create="create"
			:showSearch="true">
			<el-table-column type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.attributes">
						<el-table-column label="Label" prop="displayName" min-width="150"></el-table-column>
						<el-table-column label="Nom" prop="name" min-width="150"></el-table-column>
						<el-table-column label="URL" prop="url" min-width="150"></el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<button-column
				type="edit"
				button-title="Modifier le fournisseur d'identité"
				:show-on-mobile="true"
				:update-function="update" />
			<el-table-column label="Label" prop="displayName" min-width="150"></el-table-column>
			<el-table-column label="Nom" prop="name" min-width="150"></el-table-column>
			<el-table-column label="URL" prop="url" min-width="150"></el-table-column>
			<button-column
				type="edit"
				button-title="Modifier le fournisseur d'identité"
				:show-on-mobile="false"
				:update-function="update" />
		</crud-list>
	</div>
</template>

<script>
import AxiosApiService from '@/services/axios/axios.api.service';
import CrudList from '@/components/Table/CrudList';
import ButtonColumn from '@/components/Table/ButtonColumn';
import ROLES from '@/enums/Roles';

export default {
	name: 'Idp',
	components: {
		CrudList,
		ButtonColumn,
	},
	data() {
		return {
			idps: [],
			createAllowedAccount: [ROLES.ADMINISTRATOR],
		};
	},
	methods: {
		async fetch() {
			({ data: this.idps } = await AxiosApiService.get('/idps'));
			return this.idps;
		},
		create() {
			this.$router.push({ name: 'idpCreation' });
		},
		update(row, event) {
			this.$router.push({ name: 'idpDetails', params: { id: row.id } });
		},
	},
	created() {
		this.fetch();
	},
};
</script>
