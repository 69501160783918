<template>
	<div>
		<!-- CONTENT -->
		<div class="login-page">
			<div class="wrapper wrapper-full-page">
				<div class="full-page login-page section-image">
					<!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
					<div class="content" style="z-index: 5">
						<div class="row justify-content-center mx-0 navbar-header">
							<div class="col-xl-5 d-flex flex-column flex-md-col">
								<div>
									<h2 align="center" class="ml-auto mr-auto login-title">
										Après authentification, vous serez automatiquement redirigé sur l'application
										<el-dropdown @command="handleCommand" trigger="click" placement="top" class="apps">
											<div>
												<h2 class="selected-application" style="margin-bottom: 0px">
													{{ resource.displayName }}
													<i class="el-icon-arrow-down el-icon--right"></i>
												</h2>
											</div>
											<el-dropdown-menu>
												<el-dropdown-item v-for="(r, key) in resources" :key="key" :command="r">
													{{ r.displayName }}
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</h2>
								</div>
							</div>
						</div>
						<login-idps class="row justify-content-center mx-0" :resourceLink="resLink" :resourceName="resource.name" />
						<div class="support-message-container row justify-content-center mx-0">
							<div class="col-xl-6 d-flex flex-column justify-content-center text-center">
								<label class="support-message">
									<p>
										En vous authentifiant, vous acceptez notre
										<a :href="`${locationOrigin}/privacy_policy`">Politique de confidentialité des données</a>
										.
									</p>
									<p>
										Vous avez besoin d'assistance ? Contactez le support sur
										<a :href="`${this.supportURL}`">support.ciip.ch</a>
										.
									</p>
									<br />
								</label>
							</div>
						</div>
					</div>
					<!-- FOOTER -->
					<footer class="footer footer-black footer-white login-footer" style="z-index: 3">
						<div class="container-fluid">
							<nav class="pull-left">
								<ul>
									<li>
										<a href="http://www.ciip.ch/">
											Conférence intercantonale de l'instruction publique de la Suisse romande et du Tessin
										</a>
									</li>
								</ul>
							</nav>
							<div class="copyright pull-right">
								<span>
									Développé avec
									<i class="fa fa-heart heart"></i>
									à l'
									<a href="http://www.iict.ch" target="_blank">IICT</a>
								</span>
							</div>
						</div>
					</footer>
					<div class="full-page-background" :style="backgroundImage"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { RESOURCES } from '@/enums/Resources';
import LoginIdps from '@/components/Login/LoginIdps.vue';

export default {
	data() {
		return {
			resources: RESOURCES,
			resource: {
				name: this.formatAppName(window.location.origin),
				displayName: 'Localhost',
			},
			supportURL: this.$myenv.SUPPORT_URL,
			spURL: this.$myenv.SP_URL,
			resLink: window.location.origin,
			locationOrigin: window.location.origin,
			numberOfBackgrounds: 9,
			backgroundNumber: 0,
		};
	},
	components: {
		LoginIdps,
	},
	created() {
		this.checkRouteParam();

		// Select a random background.
		this.backgroundNumber = Math.floor(Math.random() * this.numberOfBackgrounds) + 1;
	},
	mounted() {
		this.checkRouteParam();
	},
	beforeDestroy() {
		this.closeMenu();
	},
	computed: {
		backgroundImage() {
			if (this.backgroundNumber) {
				return `background-image: url('static/img/backgrounds/${this.backgroundNumber}.jpg');`;
			} else {
				return '';
			}
		},
	},
	methods: {
		checkRouteParam() {
			if (this.$route.query.appURL) {
				this.resource = {
					link: this.$route.query.appURL,
					name: this.formatAppName(this.$route.query.appURL),
				};
				this.resLink = this.$route.query.appURL;
			}
		},
		toggleNavbar() {
			document.body.classList.toggle('nav-open');
		},
		closeMenu() {
			document.body.classList.remove('nav-open');
			document.body.classList.remove('off-canvas-sidebar');
		},
		formatAppName(appName) {
			let appToString = appName.toUpperCase().replace('HTTPS://', '');
			// remove all content after a /
			if (appToString.indexOf('/') > 0) {
				appToString = appToString.substring(0, appToString.indexOf('/'));
			}
			return appToString;
		},
		handleCommand(command) {
			if (command.name === 'PLANDETUDES.CH') {
				window.location.href = command.link;
			} else {
				this.resource = command;
				this.resLink = command.link;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.login-title,
.selected-application,
.support-message,
.login-footer {
	color: #fff !important;
}

.selected-application {
	font-weight: 600;
}

.support-message {
	font-size: 0.8em;

	p {
		margin-bottom: 0.5em !important;
	}
}

.content {
	padding-top: 16.5vh !important;
}

.navbar-header {
	text-align: center;
}

.apps {
	cursor: pointer;
}

.full-page-background {
	background-size: cover;
}

a {
	color: #fff;
	text-decoration: underline;
}

@media screen and (max-width: 767px) {
	.support-message-container {
		margin-top: 1rem;
	}
}

@media screen and (max-width: 1280px) {
	.content {
		padding-top: 5vh !important;
	}

	.footer {
		position: relative !important;

		.pull-left {
			text-align: center;
			width: 100%;

			ul li a {
				padding: 0;
			}
		}

		.copyright,
		.pull-right {
			margin-top: 0.5rem;
			text-align: center !important;
			width: 100%;
		}
	}
}
</style>
