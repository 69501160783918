<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="closeModal"
  >
    <h5 v-if="isUpdate">Changer {{resource.name}}</h5>
    <h5 v-else>Ajouter une application</h5>
    <form>
      <div class="form-group">
        <label class="control-label">Nom</label>
        <el-input
          type="text"
          v-model="resource.name"
          name="name"
          :disabled="isUpdate"
        >
        </el-input>
      </div>
			<div class="form-group">
				<label class="control-label">URL</label>
				<el-input
					type="url"
					v-model="resource.url"
					name="url"
					:disabled="isUpdate"
				>
				</el-input>
			</div>
      <div class="form-group">
        <label class="control-label">Roles</label>
        <el-checkbox-group v-model="resource.roles" class="checkbox-container">
          <el-checkbox v-for="role in roles" :key="role.name" :label="role.name">{{ getFrRoleName(role) }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </form>
    <p v-for="error in errorMessage" :key="error" class="error-message">
      {{ error}}
    </p>
    <div
      slot="footer"
      class="justify-content-center d-flex"
    >
      <p-button
        v-if="isUpdate"
        type="success"
        class="mx-1"
        round
        @click.native="updateResource"
      >
        Mettre à jour
      </p-button>
      <p-button
        v-else
        type="success"
        class="mx-1"
        round
        @click.native="createResource"
      >
        Créer
      </p-button>
      <p-button
        v-if="isUpdate"
        type="danger"
        class="mx-1"
        round
        @click.native="deleteResource"
      >
        Supprimer
      </p-button>
      <p-button
        type="light"
        class="mx-1"
        round
        @click.native="closeModal"
      >
        Annuler
      </p-button>
    </div>
  </modal>
</template>

<script>
import swal from 'sweetalert2';
import { Col, Row } from 'element-ui';
import Modal from '../Template/Modal.vue';
import ROLES from '@/enums/Roles';
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    Modal,
  },
  props: {
    show: Boolean,
    resourceProp: Object,
    isUpdate: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    resourceProp(newVal) {
      if(newVal){
        this.resource = newVal;
      }
    }
  },
  data() {
    return {
      resource: {
        name: '',
				url: '',
        roles: [],
      },
      roles: [],
      errorMessage: [],
    }
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      try {
        const response = await AxiosApiService.get('/roles');

        if (response) {
          this.roles = response.data;
        }
        return response;
      } catch (error) {
        this.$notify({
          message: 'Une erreur est survenu lors de la récupération des roles, veuillez contacter un administrateur',
          type: 'error',
        })
      }
    },
    async updateResource() {
      this.errorMessage = [];

      try {
        const response = await AxiosApiService.patch(`/resources/${this.resource.id}`, this.resource);

        if (response) {
          this.$emit('close');
          this.$notify({
            title: "Changement de l'application fait avec succès",
            text: "Changement de l'application fait avec succès!",
            type: 'success',
          });
        }
        return response;
      } catch (error) {
        this.errorMessage.push("Une erreur c'est produite lors de la modification de l'application");
      }
    },
    async createResource() {
      this.errorMessage = [];
      if(this.resource.name !== null && this.resource.name !== '') {
        try {
          const response = await AxiosApiService.post('/resources', this.resource);

          if (response) {
            this.$emit('close');
            this.$notify({
              title: "Création de l'application fait avec succès",
              text: "Création de l'application fait avec succès!",
              type: 'success',
            });
          }
          return response;
        } catch (error) {
          this.errorMessage.push("Une erreur c'est produite lors de la création de l'application");
        }
      } else {
        this.errorMessage.push("Le nom est obligatoire et ne peut pas être vide");
      }
    },
    deleteResource() {
      this.errorMessage = [];
      swal({
          title: 'Êtes-vous sure de vouloir supprimer cette application ?',
          text: 'Il ne sera pas possible d\'annuler cette suppression !',
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Oui, effacer',
          cancelButtonText: 'Non, conserver',
          buttonsStyling: false,
        }).then(async () => {
          try {
            const response = await AxiosApiService.delete(`/resources/${this.resource.id}`);

            if (response) {
              this.$emit('close');
              this.$notify({
                title: "Suppression de l'application fait avec succès",
                text: "Suppression de l'application fait avec succès!",
                type: 'success',
              });
            }
            return response
          } catch (error) {
            this.errorMessage.push("Une erreur c'est produite lors de la suppression de l'application");
          }
        });
    },
    formatRoles(roles){
      const rolesFomatted = [];
      if(roles) {
        roles.forEach((role) => {
          const rolesDetails = Object.values(ROLES).filter((appRole => appRole.name === role.name));
          if (rolesDetails.length > 0 ) {
            rolesFomatted.push(rolesDetails[0]);
          }
        });
      }
      return rolesFomatted;
    },
    getFrRoleName(role) {
      return Object.values(ROLES).filter((appRole => appRole.name === role.name))[0].string;
    },
    closeModal() {
      this.errorMessage = [];
      this.$emit("close");
    }
  }
}
</script>
<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 12px;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
}
</style>
