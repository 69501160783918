<template>
  <div class="register-page">
    <div class="wrapper wrapper-full-page ">
      <div
        class="full-page register-page section-image"
        filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 mx-auto">
                <card
                  type="signup"
                  class="text-center">
                  <template slot="header">
                    <h4 class="card-title">Réinitialisation du mot de passe</h4>
                  </template>
                  <label class="label-email">Veuillez saisir votre adresse e-mail</label>
                  <fg-input
                    v-validate="modelValidations.email"
                    :error="getError('email')"
                    v-model="form.email"
                    class="px-md-3 mb-3"
                    type="email"
                    name="email"
                    addon-left-icon="nc-icon nc-email-85"
                    placeholder="Adresse e-mail"/>
                  <p-button
                    class="col-md-5 mx-0 my-1 my-md-3 mr-md-3"
                    type="info"
                    round
                    @click.prevent="backToLogin">Retour au Login</p-button>
                  <p-button
                    class="col-md-5 mx-0 my-1 my-md-3"
                    type="info"
                    round
                    @click.prevent="validate">Envoyer</p-button>
                </card>
              </div>
            </div>
          </div>
        </div>
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/background-2.jpg) "/>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button } from 'src/components/Template';
import { Validator } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr';
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    Card,
    [Button.name]: Button,
  },
  data() {
    return {
      form: {
        email: '',
      },
      modelValidations: {
        email: {
          required: true,
          email: true,
        },
      },
    };
  },
  mounted() {
    // Set the field validator to french
    Validator.localize('fr', {
      messages: fr.messages,
      attributes: {
        email: 'adresse e-mail',
      },
    });
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.requestPasswordReset(this.form.email);
          this.form.email = '';
          this.$validator.reset();
        } else {
          this.$notify({
            message: 'Veuillez corriger les erreurs.',
            icon: 'add_alert',
            type: 'danger',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
          });
        }
      });
    },
    async requestPasswordReset(emailAdress) {
      try {
        const data = {
          email: emailAdress,
        };

        const response = await AxiosApiService.post('/reset/resetPasswordRequest', data);

        if (response) {
          this.$notify({
            message: 'La demande de réinitialisation de votre mot de passe a été traitée. Un mail avec un lien de réinitialisation vous a été envoyé.',
            icon: 'add_alert',
            type: 'success',
            horizontalAlign: 'right',
            verticalAlign: 'bottom',
          });
        }
      } catch (error) {
        this.$notify({
          message: 'Echec de la demande de réinitialisation du mot de passe, veuillez réessayer ou contacter le webmaster.',
          icon: 'add_alert',
          type: 'danger',
          horizontalAlign: 'right',
          verticalAlign: 'bottom',
        });
      }
    },
    backToLogin() {
      this.form.email = '';
      this.$validator.reset();
      this.$router.push({ name: 'Login' });
    },
  },

};
</script>

<style scoped>
	.label-email {
		font-size: 1em;
	}
</style>
