import Vue from "vue";
import jwt from "jsonwebtoken";
import router from "@/router/router";
import ROLES from "@/enums/Roles";
import { isAllowed } from "@/services/AuthService";
import AuthorizationService from "../../services/api/authorization.service";
import AuthenticationService from "../../services/api/authentication.service";

/**
 * This is a bit complicated because some of the data e.g. roles are stored in the JWT and some others are
 * accessible through profile endpoint on the REST API.
 *
 * In order not to make too many requests, these values are stored in the localstorage and fetched only when needed.
 */
export default {
  namespaced: true,
  state: {
    email: null,
    accountId: null,
    userId: null,
    firstName: null,
    lastName: null,
    organization: null,
    token: null,
    expiration: null,
    roles: [],
  },
  mutations: {
    setToken(state, token) {
      // Either update token from database or set it
      if (token != null) {
        // Check validity
        const decoded = jwt.decode(token);
        let expiration = decoded.exp * 1000;
        if (new Date().getTime() < expiration) {
          state.accountId = decoded.sub;
          state.email = decoded.accountEmail;
          state.firstName = decoded.firstName;
          state.lastName = decoded.lastName;
          state.organization = decoded.organization;
          state.userId = decoded.userId;
          state.expiration = expiration;
          state.token = token;
          let roles = JSON.parse(decoded.roles);
          if (roles[process.env.VUE_APP_NAME]) {
            state.roles = roles[process.env.VUE_APP_NAME].map((role) => {
              return Object.values(ROLES).find((appRole) => appRole.name === role);
            });
          }
        }
      }
    },
    clear(state) {
      state.email = null;
      state.token = null;
      state.roles = [];
      state.firstName = null;
      state.lastName = null;
      state.organization = null;
      state.expiration = null;
      state.accountId = null;
      state.userId = null;
    },
  },
  actions: {
    init({ commit, dispatch }) {
    },
    async login({ commit, dispatch }, auto) {
      try {
        // Make the request to get the JWT
        const response = await AuthorizationService.getJWT();

        // If a reponse is send with the token, save it in the store with user data
        if (response && response.data.token != null) {
          commit("setToken", response.data.token);
          Vue.prototype.$notifications.notify({
            message: "La connexion a réussi!",
            title: "Succès",
            icon: "fas fa-check",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "success",
            timeout: 5000,
          });
          return response;
        } else {
          throw new Error("No shibboleth session");
        }
      } catch(error) {
        // If the 1st request did not worked, the autorization might come from oauth2,
        // so try to get a JWT from oauth2
        const response = await AuthorizationService.oauth2();

        // If a response is send with a JWT, save it with the user data inside of it
        if (response && response.data.token != null) {
          commit("setToken", response.data.token);
          if (auto) {
            Vue.prototype.$notifications.notify({
              message: "La connexion a réussi!",
              title: "Succès",
              icon: "fas fa-check",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "success",
              timeout: 5000,
            });
          }
          return response;
        } else {
          throw new Error("No idm session");
        }
      }
    },
    async logoutShib({ commit }) {
      try {
        commit("clear");
        window.location.href = window.location.origin + "/Shibboleth.sso/Logout?return=" + window.location.origin;
      } catch(error) {
        Vue.prototype.$notifications.notify({
          message: "Une erreur est survenue lors de la déconnexion avec votre fournisseur d'identité. Veillez à quitter votre navigateur pour complèter la déconnexion.",
          title: "Attention",
          icon: "fas fa-times",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "danger",
          timeout: 5000,
        });
      }
    },
    async logout({ commit, dispatch }) {
      await dispatch('logoutShib').then(async (res) => {
        try {
          // const response = await AuthorizationService.logoutAPI();

          if (res) {
            Vue.prototype.$notifications.notify({
              message: "Vous avez correctement été déconnecté de l'application ! Pour compléter la déconnexion, vous devez quitter votre navigateur !",
              title: "Succès",
              icon: "fas fa-check",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "success",
              timeout: 5000,
            });
            // Check if the account still has access to the current route
            if (!isAllowed(router.currentRoute)) {
              router.push('/login');
            }
          }
        } catch(error) {
          Vue.prototype.$notifications.notify({
            message: "Une erreur est survenue lors de la déconnexion avec votre fournisseur d'identité. Veillez à quitter votre navigateur pour complèter la déconnexion.",
            title: "Attention",
            icon: "fas fa-times",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger",
            timeout: 5000,
          });
        }
      });
    },
  },
  getters: {
    email: (state) => state.email,
    accountId: (state) => state.accountId,
    userId: (state) => state.userId,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    organization: (state) => state.organization,
    roles: (state) => state.roles,
    token: (state) => state.token,
    expiration: (state) => state.expiration,
  },
};
