const ROLES = {
	USER: {
		name: "user",
		string: "Utilisateur",
		value: 1,
	},
	ADMINISTRATOR: {
		name: "administrator",
		string: "Administrateur",
		value: 2,
	},
	POWER_USER: {
		name: "poweruser",
		string: "Super Utilisateur",
		value: 2,
	},
	ORGANIZATION_USER: {
		name: "organizationuser",
		string: "Utilisateur de l'oganisation",
		value: 1,
	},
	COMMITTE_MEMBER: {
		name: "committeemember",
		string: "Membre du comité",
		value: 1,
	},
	CONTENT_EDITOR: {
		name: "contenteditor",
		string: "Editeur de contenu",
		value: 1,
	},
	EXTERNAL_EXPERT: {
		name: "externalexpert",
		string: "Expert externe",
		value: 1,
	},
	TEACHER: {
		name: "teacher",
		string: "Enseignant",
		value: 1,
	},
	STUDENT: {
		name: "student",
		string: "Etudiant",
		value: 1,
	},
	TRAINER: {
		name: "trainer",
		string: "Formateur",
		value: 1,
	},
};

export default ROLES;
