<template>
  <el-table-column
    v-if="showComponent"
    label=""
    :width="showOnMobile ? 62 : 80">
    <div
      slot-scope="slotScope"
      class="table-items-list-item">
        <i
          @click="updateFunction(slotScope.row, $event)"
          v-bind:class="{ 'el-icon-delete': type === deleteType, 'el-icon-edit': type !== deleteType }" />
    </div>
  </el-table-column>
</template>

<script>
export default {
  name: 'ButtonColumn',
  props: {
    type: String, // Either 'edit' or 'delete', change the button color and the icon.
    buttonTitle: String,
    showOnMobile: Boolean, // If true show the component on mobile only, if false show it on desktop only.
    updateFunction: Function,
  },
  data() {
    return {
      mobileWindowMaxWidth: 991,
      deleteType: 'delete',
      windowInnerWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  computed: {
    // Based on the showOnMobile prop and the size of the window, return true if the component has to be shown of false otherwise.
    showComponent() {
      const isWebsiteOnMobileMode = this.windowInnerWidth <= this.mobileWindowMaxWidth;
      return (isWebsiteOnMobileMode && this.showOnMobile) || (!isWebsiteOnMobileMode && !this.showOnMobile);
    },
  },
  methods: {
    resize() {
      this.windowInnerWidth = window.innerWidth;
    },
  },
};
</script>
