<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="closeModal"
  >
    <h5>Vos coordonnées</h5>
    <form>
      <div class="form-group">
        <label class="control-label">Prénom</label>
        <input
          v-model="account.firstName"
          v-validate="modelValidations.firstName"
          class="form-control"
          type="text"
          name="firstName"
        >
        <small
          v-show="firstName.invalid"
          class="text-danger"
        >
          Le prénom est requis.
        </small>
      </div>
      <div class="form-group">
        <label class="control-label">Nom</label>
        <input
          v-model="account.lastName"
          v-validate="modelValidations.lastName"
          class="form-control"
          type="text"
          name="lastName"
        >
        <small
          v-show="lastName.invalid"
          class="text-danger"
        >
          Le nom est requis.
        </small>
      </div>
      <div class="form-group">
        <label class="control-label">Email</label>
        <input
          v-model="account.email"
          v-validate="modelValidations.email"
          class="form-control"
          type="email"
          name="email"
        >
        <small
          v-show="email.invalid"
          class="text-danger"
        >
          L'email est requis.
        </small>
      </div>
      <h5>Roles</h5>
      <div v-for="resource in account.resources" :key="resource.id" class="form-group">
        <label class="control-label">{{ resource.name }}</label>
        <el-checkbox-group v-model="resource.roles" class="checkbox-container">
          <el-checkbox v-for="role in resource.rolesPossible" :key="role.name" :label="role.name">{{role.string}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </form>
    <p v-for="error in errorMessage" :key="error" class="error-message">
      {{ error }}
    </p>
    <div
      slot="footer"
      class="justify-content-center d-flex"
    >
      <span >&nbsp;</span>
      <p-button
        type="success"
        round
        @click.native="updateAccount"
      >
        Mettre à jour
      </p-button>
      <span>&nbsp;</span>
      <p-button
        type="light"
        round
        @click.native="closeModal"
      >
        Annuler
      </p-button>
    </div>
  </modal>
</template>

<script>
import { Col, Row } from 'element-ui';
import { mapFields } from 'vee-validate';
import ROLES from '@/enums/Roles';
import Modal from '@/components/Template/Modal.vue';
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    Modal,
  },
  props: {
    show: Boolean,
    accountProps: Object,
  },
  data() {
    return {
      account: {
        firstName: null,
        lastName: null,
        email: null,
      },
      resources: [],
      errorMessage: [],
      modelValidations: {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        email: {
          required: true,
        },
      },
    }
  },
  computed: {
    ...mapFields(['firstName', 'lastName', 'email']),
  },
  watch: {
    accountProps(newVal) {
      if(newVal) {
        this.account = newVal;
        this.account.resources = this.account.resources.map((resource) => {
          return {
            ...resource,
            rolesPossible: this.getRolesPossibility(resource),
          }
        });
      }
    },
  },
  created() {
    this.fetchResources();
  },
  methods: {
    async fetchResources() {
      try {
        const response = await AxiosApiService.get('/resources');
        
        if (response) {
          this.resources = res.data.map((resource) => {
            return {
              ...resource,
              roles: this.formatRoles(resource.roles),
            };
          })
        }
        return response;
      } catch (error) {
        this.$notify({
          message: 'Une erreur est survenu lors de la récupération des applications, veuillez contacter un administrateur',
          type: 'error',
        })
      }
    },
    updateAccount() {
      this.$validator.validateAll()
        .then(async (result) => {
          if(result) {
            try {
              const response = await AxiosApiService.patch(`/accounts/${this.account.id}`, this.account);
              
              if (response) {
                this.$emit('close');
              }
              return response;
            } catch (error) {
              this.errorMessage.push(error.message);
            }
          } else {
            this.errorMessage.push("Un ou plusieurs champs ne sont pas valide");
          }
        }
      );
    },
    formatRoles(roles) {
      const rolesFomatted = [];
      if(roles) {
        roles.forEach((role) => {
          const rolesDetails = Object.values(ROLES).filter((appRole => appRole.name === role));
          if (rolesDetails.length > 0 ) {
            rolesFomatted.push(rolesDetails[0]);
          }
        });
      }
      return rolesFomatted;
    },
    getRolesPossibility(resource) {
      return this.resources.filter((app) => app.name === resource.name)[0].roles;
    },
    closeModal() {
      this.$emit("close");
    },
  }
}
</script>
<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  flex-direction: column;
}
</style>
