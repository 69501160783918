import Axios from 'axios';
import store from '@/store';

const AxiosAuthService = {
  init(baseURL) {
    this.axios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: baseURL + "/api",
      withCredentials: true,
    });

    this.axios.interceptors.response.use(
      (config) => config,
      async (error) => {
        // Logs out whenever there is an unauthorized response.
        if (error.response && error.response.status === 401) {
          await store.dispatch("account/logout");
        }

        return Promise.reject(error);
      },
    );
  },

  /**
   * Sends a GET request to get a jwt token. The withCredentials in the init function is MANDATORY
   * @param {String} path A relative path to the resource.
   */
  async get(path) {
    return this.axios.get(path );
  },
};

export default AxiosAuthService;