import { render, staticRenderFns } from "./CrudList.vue?vue&type=template&id=7bb86289&scoped=true"
import script from "./CrudList.vue?vue&type=script&lang=js"
export * from "./CrudList.vue?vue&type=script&lang=js"
import style0 from "./CrudList.vue?vue&type=style&index=0&id=7bb86289&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb86289",
  null
  
)

export default component.exports