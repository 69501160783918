<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://www.ciip.ch/">
              CONFÉRENCE INTERCANTONALE DE L'INSTRUCTION PUBLIQUE DE LA SUISSE
              ROMANDE ET DU TESSIN
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        Développé avec
        <i class="fa fa-heart heart"></i>
        <span>
          à l'
          <a href="http://iict.heig-vd.ch/" rel="noopener" target="_blank">IICT</a>
        </span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style scoped></style>
