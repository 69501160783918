<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcumb-user">
      <el-breadcrumb-item :to="{ name: 'accounts' }">Comptes</el-breadcrumb-item>
      <el-breadcrumb-item> {{account.lastName}} {{account.firstName}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div v-show="loading" class="text-center">
        <loading class="loading"></loading>
      </div>
      <div>
        <h5 class="title">{{ title }}</h5>
        <form>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="12">
              <div class="form-group">
                <label class="control-label">Prénom</label>
                <input
                  v-model="account.firstName"
                  v-validate="modelValidations.firstName"
                  class="form-control"
                  type="text"
                  name="firstName"
                  :disabled="!isNewAccount"
                >
                <small
                  v-show="firstName.invalid"
                  class="text-danger"
                >
                  Le prénom est requis.
                </small>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12">
              <div class="form-group">
                <label class="control-label">Nom</label>
                <input
                  v-model="account.lastName"
                  v-validate="modelValidations.lastName"
                  class="form-control"
                  type="text"
                  name="lastName"
                  :disabled="!isNewAccount"
                >
                <small
                  v-show="lastName.invalid"
                  class="text-danger"
                >
                  Le nom est requis.
                </small>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="12">
              <div class="form-group">
                <label class="control-label">Email</label>
                <input
                  v-model="account.email"
                  v-validate="modelValidations.email"
                  class="form-control"
                  type="email"
                  name="email"
                  :disabled="!isNewAccount"
                >
                <small
                  v-show="email.invalid"
                  class="text-danger"
                >
                  L'email est requis.
                </small>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12">
              <div class="form-group">
                <label class="control-label">Organisation</label>
                <input
                  v-model="account.organization"
                  v-validate="modelValidations.organization"
                  class="form-control"
                  type="text"
                  name="organization"
                  :disabled="!isNewAccount"
                >
                <small
                  v-show="email.invalid"
                  class="text-danger"
                >
                  L'email est requis.
                </small>
              </div>
            </el-col>
          </el-row>
          <div class="app-roles-container">
            <h5 class="title">Roles</h5>
            <el-row class="app-roles">
              <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="resource in account.resources" :key="resource.id" class="form-group">
                <label class="control-label">{{ resource.name }}</label>
                <el-checkbox-group v-model="resource.roles" class="checkbox-container">
                  <el-checkbox v-for="role in resource.rolesPossible" :key="role.name" :label="role.name">
                    {{role.string}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
          </div>
        </form>
        <p v-for="error in errorMessage" :key="error" class="error-message">
          {{ error }}
        </p>
        <div
          class="justify-content-center d-flex"
        >
          <span >&nbsp;</span>
          <p-button
            v-if="isNewAccount"
            type="success"
            round
            @click.native="createAccount"
          >
            Enregistrer
          </p-button>
          <div v-else>
          <p-button
            type="success"
            round
            @click.native="updateAccount"
          >
            Mettre à jour
          </p-button>
          <span>&nbsp;</span>
          <p-button
            type="danger"
            round
            @click.native="deleteAccount"
          >
            Supprimer
          </p-button>
          </div>
          <span>&nbsp;</span>
          <p-button
            type="light"
            round
            @click.native="cancelHandler"
          >
            Annuler
          </p-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { Col, Row, Card, Breadcrumb} from 'element-ui';
import { mapFields } from 'vee-validate';
import ROLES from '@/enums/Roles';
import Loading from "@/components/Loading.vue";
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Card.name]: Card,
    [Breadcrumb.name]: Breadcrumb,
    Loading,
  },
  created() {
    this.accountId = this.$route.params.id;
    this.isNewAccount = this.accountId ? false : true;
    if (!this.isNewAccount) {
      this.fetchResources()
      .then(() => this.fetchAccount());
    } else {
      this.fetchResources();
    }
  },
  data() {
    return {
      isNewAccount: true,
      accountId: null,
      account: {
        firstName: null,
        lastName: null,
        email: null,
        organization: null,
        resources: null,
      },
      resources: [],
      errorMessage: [],
      modelValidations: {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        email: {
          required: true,
        },
        organization: {
          required: true,
        },
      },
      loading: false,
    }
  },
  computed: {
    ...mapFields(['firstName', 'lastName', 'email', 'organization']),
    title() {
      if (this.isNewAccount) {
        return 'Ajouter un nouvel utilisateur';
      }
      return `Informations du compte associé à ${this.account.firstName} ${this.account.lastName}`;
    },
  },
  methods: {
    async fetchAccount() {
      this.loading = true;
      try {
        const response = await AxiosApiService.get(`/accounts/${this.accountId}`);
      
        if (response && response.data) {
          this.account = response.data;
          const resourcesAccount = this.account.resources;
          this.account.resources = this.resources.map(resource => {
              return{
              ...resource,
              rolesPossible: resource.roles,
            };
          })

          if(resourcesAccount) {
            this.account.resources = this.account.resources.map((resource) => {
              return {
                ...resource,
                roles: this.getAccountRoles(resource, resourcesAccount),
              }
            });
          }
        }
        this.loading = false;
        return response;
      } catch (error) {
        this.loading = false;
        this.$notify({
          message: "Une erreur est survenu lors de la récupération des informations de l'utilisateur, veuillez contacter un administrateur",
          type: 'error',
        })
      }
    },
    async fetchResources() {
      this.loading = true;

      try {
        const response = await AxiosApiService.get('/resources');
      
        if (response) {
          this.resources = response.data.map((resource) => {
            return {
              ...resource,
              roles: this.formatRoles(resource.roles),
            };
            
          })
          if(this.isNewAccount) {
              this.account.resources = this.resources.map((resource) => {
                return {
                  ...resource,
                  rolesPossible: resource.roles,
                  roles: [],
                }
            });
          }
        }
        this.loading = false;
        return response;
      } catch (error) {
        this.loading = false;
        this.$notify({
          message: 'Une erreur est survenu lors de la récupération des applications, veuillez contacter un administrateur',
          type: 'error',
        })
      }
    },
    createAccount() {
      let accounts = [];
      accounts.push(this.account);
      this.$validator.validateAll().then(async (result) => {
        if(result) {
          try {
            const response = await AxiosApiService.post('/accounts', accounts);
          
            if (response) {
              this.$notify({
                message: "La création de l'utilisateur a été réalisée avec succès",
                icon: 'add_alert',
                type: 'success',
                horizontalAlign: 'right',
                verticalAlign: 'top',
              });
              this.$router.push({ name: "accounts" });
            }
            return response;
          } catch (error) {
            this.$notify({
              message: "Une erreur est survenu lors de la création de l'utilisateur, veuillez contacter un administrateur !",
              type: 'error',
            })
          }
        } else {
          this.errorMessage.push("Un ou plusieurs champs ne sont pas valide");
        }
      })
    },
    updateAccount() {
      this.$validator.validateAll()
        .then(async (result) => {
          if(result) {
            try {
              const response = await AxiosApiService.patch(`/accounts/${this.account.id}`, this.account);
            
              if (response) {
                this.$notify({
                  message: "Modification de l'utilisateur réalisé avec succès",
                  icon: 'add_alert',
                  type: 'success',
                  horizontalAlign: 'right',
                  verticalAlign: 'top',
                });
                this.$router.push({ name: "accounts" });
              }
              return response;
            } catch (error) {
              this.$notify({
                message: "Une erreur est survenu lors de la modification des informations de l'utilisateur, veuillez contacter un administrateur !",
                type: 'error',
              })
            }
          } else {
            this.errorMessage.push("Un ou plusieurs champs ne sont pas valide");
          }
        }
      );
    },
    async deleteAccount() {
      try {
        const response = await AxiosApiService.delete(`/accounts/${this.account.id}`);
      
        if (response) {
          this.$notify({
            message: "Le compte a été supprimé avec succès",
            icon: 'add_alert',
            type: 'success',
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
          this.$router.push({ name: "accounts" });
        }
        return response;
      } catch (error) {
        this.$notify({
          message: "Une erreur est survenu lors de la suppression du compte, veuillez contacter un administrateur !",
          type: 'error',
        })
      }
    },
    formatRoles(roles) {
      const rolesFomatted = [];
      if(roles) {
        roles.forEach((role) => {
          const rolesDetails = Object.values(ROLES).filter((appRole => appRole.name === role));
          if (rolesDetails.length > 0 ) {
            rolesFomatted.push(rolesDetails[0]);
          }
        });
      }
      return rolesFomatted;
    },
    getAccountRoles(resource, resourcesAccount) {
      if(resourcesAccount.length > 0) {
        const roles = resourcesAccount.filter((app) => app.name === resource.name)[0];
        return roles ? roles.roles : [];
      }
      return [];
    },
    cancelHandler() {
      this.$router.push({ name: "accounts" })
    },
  }
}
</script>
<style lang="scss" scoped>
.title {
  padding-bottom: 10px;
}
.app-roles-container {
  padding-top: 20px;
}
.app-roles {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
}
.breadcumb-user {
  margin-bottom: 20px;
}
</style>