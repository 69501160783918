<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="closeModal"
  >
    <h5>Changer votre mot de passe</h5>
    <form>
      <div class="form-group">
        <label class="control-label">Ancien mot de passe</label>
        <el-input
          v-model="account.oldPassword"
          v-validate="modelValidations.oldPassword"
          show-password
          type="password"
          name="oldPassword"
        >
        </el-input>
        <small
          v-show="oldPassword.invalid"
          class="text-danger"
        >
          Veuillez entrer votre ancien mot de passe correctement
        </small>
      </div>
      <div class="form-group">
        <label class="control-label">Nouveau mot de passe</label>
        <el-input
          v-model="account.newPassword"
          v-validate="modelValidations.newPassword"
          show-password
          type="password"
          name="newPassword"
        >
        </el-input>
        <small
          v-show="newPassword.invalid"
          class="text-danger"
        >
          Les mdp ne sont pas similaires ou sont vide 
        </small>
      </div>
      <div class="form-group">
        <label class="control-label">Répéter le nouveau mot de passe</label>
        <el-input
          v-model="account.newPasswordBis"
          v-validate="modelValidations.newPasswordBis"
          show-password
          type="password"
          name="newPasswordBis"
        >
        </el-input>
        <small
          v-show="newPasswordBis.invalid"
          class="text-danger"
        >
          Les mdp ne sont pas similaires ou sont vide 
        </small>
      </div>
    </form>
    <p v-for="error in errorMessage" :key="error" class="error-message">
      {{ error}}
    </p>
    <div
      slot="footer"
      class="justify-content-center d-flex"
    >
      <span >&nbsp;</span>
      <p-button
        type="success"
        round
        @click.native="updatePassword"
      >
        Mettre à jour
      </p-button>
      <span>&nbsp;</span>
      <p-button
        type="light"
        round
        @click.native="closeModal"
      >
        Annuler
      </p-button>
    </div>
  </modal>
</template>

<script>
import { Col, Row, Input } from 'element-ui';
import { mapFields } from 'vee-validate';
import Modal from '../Template/Modal.vue';
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Input.name]: Input,
    Modal,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      account: {
        newPassword: null,
        newPasswordBis: null,
        oldPassword: null,
      },
      errorMessage: [],
      modelValidations: {
        oldPassword: {
          required: true,
        },
        newPassword: {
          required: true,
        },
        newPasswordBis: {
          required: true,
        },
      },
    }
  },
  computed: {
    ...mapFields(['oldPassword', 'newPassword', 'newPasswordBis']),
  },
  methods: {
    updatePassword() {
      this.errorMessage = [];
      this.$validator.validateAll()
        .then(async (result) => {
          if (result && this.account.newPassword === this.account.newPasswordBis) {
            const passwords = {
              oldPassword: this.account.oldPassword,
              newPassword: this.account.newPassword,
            };

            try {
              const response = await AxiosApiService.post('/accounts/me/changePassword', passwords);
              
              if (response) {
                this.$emit('closeModal');
                this.$notify({
                  title: 'Changement de mot de passe réussi avec succès',
                  text: 'Votre mot de passe a été changé avec succès!',
                  type: 'success',
                });
              }
              return response;
            } catch (error) {
              this.errorMessage.push("Votre ancien mot de passe est incorrect");
              this.oldPassword.invalid = true;
              this.oldPasswordBis.invalid = true;
            }
          } else {
            if(!result) {
              this.errorMessage.push("Un ou plusieurs champs n'a pas été remplie.");
            }
            if(this.account.newPasswordBis !== this.account.newPassword) {
              this.newPassword.invalid = true;
              this.oldPasswordBis.invalid = true;
              this.errorMessage.push("les deux mot de passe ne sont pas similaire");
            }
          }
        }
      );
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
}
</script>
<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 12px;
}
</style>
