<template>
  <div>
    <div class="card">
      <div class="card-header">
        <el-row>
          <el-col :lg="8" :md="10" :sm="10" :xs="24">
            <h5 class="title">
              {{ title }}
            </h5>
          </el-col>
          <el-col :lg="14" :md="12" :sm="12" :xs="20">
            <el-input
              v-if="showSearch"
              placeholder="Rechercher"
              v-model="search"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :lg="2" :md="2" :sm="2" :xs="2">
            <div>
              <button
                v-if="!creationDisabled && isAllowedToCreate"
                id="btn-add"
                type="button"
                class="btn btn-icon btn-round btn-success"
                @click="create"
              >
                <i class="fa fa-plus" />
              </button>
            </div>
          </el-col>
        </el-row>
        <div
          v-if="loading"
          class="spinner"
        >
          <rotate-square2 />
        </div>
      </div>
      <div class="card-body table-responsive table-full-width">
        <el-table
          :data="records"
          :default-sort="defaultSort"
          class="mydatalist"
          @row-click="rowClicked"
          @sort-change="sortChange"
          stripe
        >
          <slot />
        </el-table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 pagination-info" v-if="pagination.total">
        <p class="category">
          Total: {{ pagination.total }} éléments
        </p>
      </div>
      <div class="col-sm-6">
        <p-pagination
          v-if="pagination.total > 1"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
          class="pull-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { RotateSquare2 } from 'vue-loading-spinner';
import PPagination from './Pagination.vue';

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    RotateSquare2,
    PPagination,
  },
  props: {
    title: String,
    fetchData: Function,
    creationDisabled: Boolean,
    defaultSort: Object,
    createAllowedAccount: Array,
    hideCreateButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      loading: false,
      records: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      search: '',
    };
  },
  computed: {
    isAllowedToCreate() {
      if(this.hideCreateButton) {
        return false;
      }

      if(!this.createAllowedAccount) {
        return false;
      }
      const accountRoles = this.$store.getters['account/roles'];
      return accountRoles.filter((role) => this.createAllowedAccount.includes(role)).length > 0;
    }
  },
  watch: {
    'pagination.currentPage': {
      handler() {
        this.fetch();
      },
    },
    'search' : {
      handler(newVal) {
        this.pagination.currentPage = 1;
        this.$emit("search", this.search);
      }
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    rowClicked(event) {
      this.$emit('row-clicked', event);
    },
    sortChange(column, prop, order) {
      this.$emit('sort-change', column, prop, order);
    },
    fetch(searchData) {
      this.loading = true;
      this.fetchData(
        this.pagination.currentPage,
        this.pagination.perPage,
        searchData,
      ).then((response) => {
        this.loading = false;
        this.records = response.records;
        this.pagination.total = response.page.totalElements;
      });
    },
    create() {
      this.$emit('create');
    },
  },
};
</script>

<style lang="scss" scoped>
  .mydatalist >>> tr {
    cursor: pointer;
  }

  .spinner {
    position: absolute;
    top: -2px;
    right: -4px;
  }

  #btn-add {
    position: absolute;
    right: 10px;
    margin: 0px;
  }
</style>
