import Axios from 'axios';
import store from '@/store';

const AxiosSPService = {
  init(baseURL) {
    this.axios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: baseURL,
      withCredentials: true,
    });

    this.axios.interceptors.response.use(
      (config) => config,
      async (error) => {
        // Logs out whenever there is an unauthorized response.
        if (error.response && error.response.status === 401) {
          await store.dispatch("account/logout");
        }

        return Promise.reject(error);
      },
    );
  },

  /**
   * Sends a GET request with a json body to the Service Provider.
   * @param {String} path A relative path to the resource.
   * @param {*} query Object used to create a query string (if not null, path is considered not to contain a query string).
   */
  async get(path, query = null) {
    let queryString = '';
    if (query && query instanceof Object) {
      Object.entries(query).forEach(([key, value], index) => {
        queryString += index > 0 ? '&' : '?';
        queryString += `${key}=${value}`;
      });
    }

    return this.axios.get(path + queryString);
  },
};

export default AxiosSPService;