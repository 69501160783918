<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="closeModal"
  >
    <h5>Ajouter un utilisateur</h5>
    <form>
      <div class="form-group">
        <label class="control-label">Prénom</label>
        <input
          v-model="account.firstName"
          v-validate="modelValidations.firstName"
          class="form-control"
          type="text"
          name="firstName"
        >
        <small
          v-show="firstName.invalid"
          class="text-danger"
        >
          Le prénom est requis.
        </small>
      </div>
      <div class="form-group">
        <label class="control-label">Nom</label>
        <input
          v-model="account.lastName"
          v-validate="modelValidations.lastName"
          class="form-control"
          type="text"
          name="lastName"
        >
        <small
          v-show="lastName.invalid"
          class="text-danger"
        >
          Le nom est requis.
        </small>
      </div>
      <div class="form-group">
        <label class="control-label">Email</label>
        <input
          v-model="account.email"
          v-validate="modelValidations.email"
          class="form-control"
          type="email"
          name="email"
        >
        <small
          v-show="email.invalid"
          class="text-danger"
        >
          L'email est requis.
        </small>
      </div>
    </form>
    <p>Le mot de passe sera genéré par le backend et envoyé à l'utilisateur par email.</p>
    <p v-for="error in errorMessage" :key="error" class="error-message">
      {{ error }}
    </p>
    <div
      slot="footer"
      class="justify-content-center d-flex"
    >
      <span >&nbsp;</span>
      <p-button
        type="success"
        round
        @click.native="createAccount"
      >
        Créer
      </p-button>
      <span>&nbsp;</span>
      <p-button
        type="light"
        round
        @click.native="closeModal"
      >
        Annuler
      </p-button>
    </div>
  </modal>
</template>

<script>
import { Col, Row } from 'element-ui';
import { mapFields } from 'vee-validate';
import Modal from '../Template/Modal.vue';
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    Modal,
  },
  props: {
    show: Boolean,
  },
  computed: {
    ...mapFields(['firstName', 'lastName', 'email']),
  },
  data() {
    return {
      account: {
        firstName: null,
        lastName: null,
        email: null,
      },
      modelValidations: {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        email: {
          required: true,
        },
      },
    }
  },
  methods: {
    createAccount() {
      this.$validator.validateAll()
        .then(async (result) => {
          if(result) {
            try {
              const response = await AxiosApiService.post('/accounts', this.account);
              
              if (response) {
                this.$emit('close');
              }
              return response;
            } catch (error) {
              this.errorMessage.push(error.message);
            }
          } else {
            this.errorMessage.push("Un ou plusieurs champs ne sont pas valide");
          }
        }
      );
    },
    closeModal() {
      this.$emit("close");
    }
  }
}
</script>