import { render, staticRenderFns } from "./NotAllowedPage.vue?vue&type=template&id=360b1607&scoped=true"
import script from "./NotAllowedPage.vue?vue&type=script&lang=js"
export * from "./NotAllowedPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360b1607",
  null
  
)

export default component.exports