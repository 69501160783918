/**
 * Return the icons for the given abbreviation
 * @param abbreviation Abbreviation of the domain
 */
export const domainAbbreviationsIcons = function(abbreviation) {
  switch (abbreviation) {
    case "L":
      return "fa fa-language";
    case "MSN":
      return "fa fa-square-root-alt";
    case "SHS":
      return "fa fa-users";
    case "A":
      return "fa fa-paint-brush";
    case "CM":
      return "fa fa-running";
    case "FG":
      return "fa fa-star";
    default:
      return "far fa-circle";
  }
};
