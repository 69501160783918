export default [
  {
    name: "Profil",
    icon: "fa fa-user-cog",
    path: "/profile",
  },
  {
    name: "Comptes",
    icon: "fa fa-users",
    path: "/accounts",
  },
  {
    name: "Applications",
    icon: "fab fa-windows",
    path: "/resources",
  },
  {
    name: "Gestion des IDPs",
    icon: "fas fa-home",
    path: "/idps",
  },
  {
    name: "Privacy Policy",
    icon: "fas fa-question-circle",
    path: "/privacy_policy",
  },
];
