import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Load store modules dynamically.
import account from "./modules/account";
import settings from "./modules/settings";

export default new Vuex.Store({
  modules: {
    account,
    settings,
  },
  actions: {
    initialiseStore({ commit, dispatch }) {
      console.log("Initializing store");

      dispatch("account/init");
      dispatch("settings/init");
    },
  },
});
