<template>
  <div>
    <crud-list
      :fetch-data="fetchAccounts"
      ref="crudList"
      title="Comptes"
      :createAllowedAccount="createAllowedAccount"
      :defaultSort="{prop: 'lastName', order: 'ascending'}"
      @sort-change="sortChange"
      @search="searchAccount"
      @create="createAccount"
      :showSearch="true"
    >
    <el-table-column type="expand">
      <template slot-scope="props">
        <el-table
          :data="props.row.resources"
        >
          <el-table-column
            label="Applications"
            prop="name"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            label="Roles"
            prop="rolesString"
            min-width="150"
          >
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <button-column
      type="edit"
      button-title="Modifier l'utilisateur"
      :show-on-mobile="true"
      :update-function="updateAccount" />
      <el-table-column
        prop="lastName"
        label="Nom"
        min-width="200"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="firstName"
        label="Prénom"
        min-width="200"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        min-width="200"
      >
      </el-table-column>
      <button-column
        type="edit"
        button-title="Modifier l'utilisateur"
        :show-on-mobile="false"
        :update-function="updateAccount" />
    </crud-list>
  </div>
</template>
<script>
import ROLES from '@/enums/Roles';
import CrudList from '@/components/Table/CrudList.vue';
import ChangeAccountModal from '@/components/Modals/ChangeAccountModal.vue';
import CreateAccountModal from '@/components/Modals/CreateAccountModal.vue';
import ButtonColumn from '@/components/Table/ButtonColumn.vue';
import AxiosApiService from '@/services/axios/axios.api.service';

export default {
  components: {
    CrudList,
    ChangeAccountModal,
    CreateAccountModal,
    ButtonColumn,
  },
  data() {
    return {
      accounts: [],
      accountSelected: null,
      createAllowedAccount: [ROLES.ADMINISTRATOR],
      sort: 'lastName,asc',
      search: '',
    }
  },
  methods: {
    async fetchAccounts(pageNumber, pageSize) {
      try {
        const query = {
          page: pageNumber - 1,
          size: pageSize,
          sort: this.sort,
          search: this.search
        }
        const response = await AxiosApiService.get(`/accounts`, query);
      
        if (response && response.data) {
          let accountsRaw = response.data.content;
          accountsRaw = accountsRaw.map(account => {
            return {
              ...account,
              resources: account.resources.map((resource) => {
                return {
                  ...resource,
                  rolesString: this.formatRoles(resource.roles),
                }
              }),
            };
          });
          return {
            records: accountsRaw,
            page: {
              last: response.data.last,
              totalElements: response.data.totalElements,
              totalPages: response.data.totalPages,
              size: response.data.size,
              number: response.data.number,
              sort: response.data.last,
              first: response.data.first,
              numberOfElements: response.data.numberOfElements,
            }
          }
        }
      } catch (error) {
        this.$notify({
          message: "Une erreur est survenu lors de la modification des informations de l'utilisateur, veuillez contacter un administrateur !",
          type: 'error',
        })
      }
    },
    formatRoles(roles) {
      let rolesString = [];
      roles.forEach((role) => {
        const rolesFormatted = Object.values(ROLES).filter((appRole => appRole.name === role));
        if (rolesFormatted.length > 0) {
          rolesString.push(rolesFormatted[0].string);
        }
      });
      return rolesString.join(', ');
    },
    updateAccount(row, event) {
      this.$router.push({ name: 'accountDetails', params: {id: row.id} });
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.$refs.crudList.fetch();
    },
    searchAccount(search) {
      if (search && search !== '' && search.length >= 2) {
        this.search = search;
        this.$refs.crudList.fetch();
      } else {
        this.search = '';
        this.$refs.crudList.fetch();
      }
    },
    createAccount() {
      this.$router.push({ name: 'accountCreation' });
    },
  }
}
</script>

<style lang="scss" scoped>
  .user-info-container {
    width: 100%;
  }
</style>
