import AxiosSPService from "../axios/axios.sp.service.js";

const AuthenticationService = {
  async logoutShib(data) {
    return AxiosSPService.get('/Shibboleth.sso/Logout', data);
  },
  /*async logoutShib(data) {
    return AxiosSPService.get('/Logout', data);
  },*/
};

export default AuthenticationService;