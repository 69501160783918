const prefix = process.env.VUE_APP_PREFIX;

export const RESOURCES= [
  {
    link: `https://${prefix}agenda.ciip.ch/`,
    displayName: `Agenda mobile`,
    name: 'AGENDA.CIIP.CH',
    oAuth: false,
  },
  {
    link: `https://${prefix}agenda.ciip.ch/admin`,
    displayName: `Agenda - Console d'administration`,
    name: 'AGENDA.CIIP.CH',
    oAuth: false,
  },
  {
    link: `https://${prefix}annuaire.ciip.ch/`,
    displayName: `Annuaire`,
    name: 'ANNUAIRE.CIIP.CH',
    oAuth: false,
  },
  {
    link: `https://${prefix}id.ciip.ch/`,
    displayName: `Gestionnaire d'accès`,
    name: 'ID.CIIP.CH',
    oAuth: false,
  },
  {
    link: `https://${prefix}intranet.ciip.ch/`,
    displayName: `Intranet`,
    name: 'INTRANET.CIIP.CH',
    oAuth: false,
  },
  // This is commented until we put MER PER and RN in production
  /*{
    link: `https://${prefix}mer.ciip.ch/`,
    displayName: `MER`,
    name: 'MER.CIIP.CH',
    oAuth: false,
  },
  {
    link: `https://${prefix}per.ciip.ch/`,
    displayName: `PER`,
    name: 'PER.CIIP.CH',
    oAuth: false,
  },
  {
    link: `https://${prefix}ressources.ciip.ch/`,
    displayName: `Ressources numériques`,
    name: 'RESSOURCES.CIIP.CH',
    oAuth: false,
  },*/
  {
    link: `https://www.plandetudes.ch/login`,
    displayName: `Plandetudes`,
    name: 'PLANDETUDES.CH',
    oAuth: false
  },
  {
    link: `https://${prefix}portail.ciip.ch/`,
    displayName: `Portail`,
    name: 'PORTAIL.CIIP.CH',
    oAuth: false,
  },
  {
    link: `https://${prefix}support.ciip.ch/`,
    displayName: `Support`,
    name: 'SUPPORT.CIIP.CH',
    oAuth: false,
  },
];